import {
  type UseInfiniteQueryResult,
  type UseQueryResult,
} from '@tanstack/react-query'
import clsx from 'clsx'
import orderBy from 'lodash/orderBy'
import { type ReactNode, useEffect, useState } from 'react'

import { Icon, LoadMore } from '@fv/client-components'
import { flattenPages, type Page } from '@fv/client-core'

import { type SortDirection } from '../../features/dispatches/types'
import { AdminCheckbox } from './AdminCheckbox'
import { AdminLoading } from './AdminLoading'

export type SortFn<T> = (data: T[], dir: SortDirection) => T[]
type KeyOf<T extends object> = Extract<keyof T, string>
export type ColumnDef<T extends object> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  hidden?: boolean
  checkbox?: {
    enabled: boolean
    checked?: boolean
    onToggle?: (checked: boolean) => void
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  key: (string & {}) | KeyOf<T> // hinted string: https://stackoverflow.com/questions/61047551/typescript-union-of-string-and-string-literals/61048124#61048124
  label?: string
  render: (data: T) => ReactNode
  sort?: SortFn<T>
  sortable?: boolean
}

export type SortDef<T extends object = object> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  key: (string & {}) | KeyOf<T>
  dir: SortDirection
}
export type AdminTableProps<T extends object> = {
  columns: Array<ColumnDef<T>>
  data?: T[]
  rowClassName?: (data: T) => string | undefined | null | object
  initialState?: {
    sort: SortDef<T>
  }
  rowKey: (data: T) => string
  onRowSelect?: (data: T) => void
  onSortChange?: (sort: SortDef<T>) => void
  disableSort?: boolean
  emptyContent?: ReactNode
}

type ColHeaderProps<T extends object> = {
  col: ColumnDef<T>
  sort: SortDef<T>
  onSort: (sort: SortDef<T>) => void
  disableSort?: boolean
}
const ColumnHeader = <T extends object>({
  col,
  sort,
  onSort,
  disableSort,
}: ColHeaderProps<T>) => {
  if (col.checkbox?.enabled) {
    return (
      <AdminCheckbox
        id={`${col.key}_all`}
        checked={col.checkbox.checked}
        onChange={e => col.checkbox?.onToggle?.(e.target.checked)}
      />
    )
  }

  return (
    <button
      disabled={col.sortable === false}
      className={clsx({
        'cursor-default': disableSort || col.sortable === false,
      })}
      onClick={() => {
        if (disableSort || col.sortable === false) return
        onSort({
          key: col.key,
          dir: sort.key === col.key && sort.dir === 'asc' ? 'desc' : 'asc',
        })
      }}
    >
      {col.label ?? col.key.toString()}
      {!disableSort && col.label && sort.key === col.key && (
        <Icon
          className="ml-3"
          icon={sort.dir === 'desc' ? 'arrow-down' : 'arrow-up'}
        />
      )}
    </button>
  )
}

export const AdminTable = <T extends object>({
  columns,
  data,
  disableSort,
  emptyContent,
  initialState,
  onRowSelect,
  onSortChange,
  rowClassName,
  rowKey,
}: AdminTableProps<T>) => {
  const sortableCols = columns.filter(c => c.sortable !== false)
  const [sort, setSort] = useState<SortDef<T>>(
    initialState?.sort ?? { key: sortableCols[0].key, dir: 'asc' },
  )
  useEffect(() => {
    if (onSortChange) {
      onSortChange(sort)
    }
  }, [sort, onSortChange])
  if (!data?.length && emptyContent) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{emptyContent}</>
  }

  let sortedData = data ?? []
  if (!onSortChange && !disableSort) {
    const colDef = columns.find(c => c.key === sort.key && c.sortable !== false)
    if (colDef?.sort) {
      sortedData = colDef.sort(data ?? [], sort.dir)
    }
    if (colDef && sort.key in (data?.[0] ?? {})) {
      sortedData = orderBy(data, sort.key, [sort.dir])
    }
  }

  return (
    <table className="table-zebra-zebra table ">
      <thead>
        <tr>
          {columns.map(c => (
            <th key={`th.${c.key}`} className={clsx({ hidden: c.hidden })}>
              <ColumnHeader
                col={c}
                sort={sort}
                onSort={setSort}
                disableSort={disableSort}
              />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedData.map(d => {
          const key = rowKey(d)
          return (
            <tr
              className={clsx(rowClassName?.(d))}
              onClick={() => onRowSelect?.(d)}
              key={rowKey(d)}
            >
              {columns.map(col => (
                <td
                  key={`${key}.${col.key}`}
                  className={clsx({ hidden: col.hidden })}
                >
                  {col.render(d)}
                </td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

type AdminQueryTableProps<T extends object> = Omit<
  AdminTableProps<T>,
  'data'
> & {
  query: UseQueryResult<T[]>
}

export const AdminQueryTable = <T extends object>({
  query,
  ...props
}: AdminQueryTableProps<T>) => {
  const data = query.data ?? []
  const emptyContent =
    query.isFetched && !query.data?.length ? props.emptyContent : undefined
  return (
    <div className="relative">
      {(query.isFetched || query.isFetching) && (
        <AdminTable data={data} emptyContent={emptyContent} {...props} />
      )}
      {query.isFetching && (
        <div className="bg-neutral/90 text-neutral-content absolute left-0 top-0 flex h-full min-h-[200px] w-full justify-center pt-10 text-lg ">
          <div>
            <AdminLoading />
          </div>
        </div>
      )}
    </div>
  )
}

type AdminInfiniteQueryTableProps<TModel extends object> = Omit<
  AdminQueryTableProps<TModel>,
  'query'
> & {
  query: UseInfiniteQueryResult<Page<TModel>>
}

export const AdminInfiniteQueryTable = <TModel extends object>({
  query,
  ...props
}: AdminInfiniteQueryTableProps<TModel>) => {
  const data = flattenPages(query.data?.pages)
  const emptyContent =
    query.isFetched && !data?.length ? props.emptyContent : undefined
  return (
    <div className="relative">
      {(query.isFetched || query.isFetching) && (
        <>
          <AdminTable data={data} emptyContent={emptyContent} {...props} />
          <LoadMore
            fetchNextPage={query.fetchNextPage}
            hasNextPage={query.hasNextPage}
            isLoading={query.isFetchingNextPage}
          />
        </>
      )}
      {query.isInitialLoading && (
        <div className="bg-neutral/90 text-neutral-content absolute left-0 top-0 flex h-full min-h-[200px] w-full justify-center pt-10 text-lg ">
          <div>
            <AdminLoading />
          </div>
        </div>
      )}
    </div>
  )
}
